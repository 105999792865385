<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            예약확인
                        </h1>
                    </v-col>
                </v-row>
                <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            예약확인
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container class="my-8">
                    <v-row>
                        <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                            예약내역
                        </v-col>

                        <v-col cols="12" md="12">
                            <template v-if="!isMobile">
                                <table class="table-top-s table-top-s-b table-font-size-1rem">
                                    <colgroup>
                                        <col style="width:10%"/>
                                        <col style="width:15%"/>
                                        <col style="width:15%"/>
                                        <col style="width:10%"/>
                                        <col style="width:10%"/>
                                        <col style="width:10%"/>
                                        <col style="width:10%"/>
                                    </colgroup>
                                    <tr>
                                        <th>객실명</th>
                                        <th>형태</th>
                                        <th>예약일</th>
                                        <th>기간</th>

                                        <th>기준인원</th>
                                        <th>예약인원</th>
                                        <th>이용요금</th>
                                    </tr>
                                    <tr
                                            v-for="(item,i) in this.roomList"
                                            :key="i">
                                        <td class="td">
                                            {{item.roomNm}}
                                        </td>
                                        <td class="td">
                                            {{item.roomForm}}
                                        </td>
                                        <td class="td">
                                            {{item.resvStartDate}}
                                        </td>
                                        <td class="td">
                                            {{item.resvDateRange}}
                                        </td>

                                        <td class="td">
                                            {{item.stdNop}}명
                                        </td>
                                        <td class="td">
                                            {{item.resvNop}}명
                                        </td>
                                        <td class="td num-right">
                                            {{item.resvPrice | comma}}원
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>전체</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="num-right">{{roomPrice | comma}}원</td>
                                    </tr>
                                </table>
                            </template>
                            <template v-else>
                                <table class="table-top-s mobile" style="border-top:2px solid gray;">
                                    <colgroup>

                                        <col style="width:35%"/>
                                        <col style="width:30%"/>
                                        <col style="width:33%"/>
                                    </colgroup>
                                    <tr>
                                        <th class="pl-4">객실</th>
                                        <th>예약일</th>
                                        <th class="num-right">요금</th>
                                    </tr>
                                    <tr
                                            v-for="(item,i) in this.roomList"
                                            :key="i">
                                        <td>{{item.roomNm}} <br> <span style="font-size: 0.7rem; color: gray;">{{item.roomForm}}</span>
                                        </td>
                                        <td>{{item.resvStartDate}}<br> <span style="font-size: 0.7rem; color: gray;">{{item.resvDateRange}}</span>
                                        </td>
                                        <td class="num-right">{{item.resvPrice | comma}}원</td>
                                    </tr>
                                    <tr style="height: 50px;">
                                        <td>전체금액</td>
                                        <td></td>
                                        <td class="num-right">{{roomPrice | comma}}원</td>
                                    </tr>
                                </table>
                            </template>


                        </v-col>
                        <v-col cols="12" class="sub-title-3 mt-4">
                            부대시설
                        </v-col>
                        <v-col cols="12" class="" md="12">
                            <template v-if="isCampItem">
                                <template v-if="!isMobile">
                                    <table class="table-top-s table-top-s-b table-font-size-1rem">
                                        <colgroup>
                                            <col style="width:30%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                        </colgroup>
                                        <tr>
                                            <th>부대시설</th>
                                            <th>단가</th>
                                            <th>수량</th>
                                            <th>단위</th>
                                            <th class="">이용요금</th>
                                        </tr>
                                        <tr
                                                v-for="(item,i) in this.campItem"
                                                :key="i">
                                            <td>{{item.campName}}</td>
                                            <td class="num-right">{{item.campPrice | comma}}원</td>
                                            <td>
                                                {{item.campQty}}
                                            </td>
                                            <td><span style="margin-left:9px;">{{item.campUnit}}</span></td>
                                            <td class="num-right">{{item.campPrice*item.campQty | comma}}</td>
                                        </tr>
                                    </table>
                                </template>
                                <template v-else>
                                    <table class="table-top-s mobile">
                                        <colgroup>
                                            <col style="width:40%"/>
                                            <col style="width:15%"/>
                                            <col style="width:25%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="pl-4">부대시설</th>
                                            <th>수량</th>
                                            <th class="num-right">요금</th>
                                        </tr>
                                        <tr
                                                v-for="(item,i) in this.campItem"
                                                :key="i">
                                            <td class="pl-4">{{item.campName}} <br> <span style="color: #1976d2 ;">({{item.campPrice | comma}}원) </span>
                                            </td>
                                            <td>{{item.campQty}}</td>
                                            <td class="num-right">{{item.campPrice*item.campQty | comma}}원</td>
                                        </tr>
                                    </table>
                                </template>

                            </template>
                            <template v-else>
                                <span style="font-size: 1rem;">부대시설 신청내역이 없습니다.</span>
                            </template>

                        </v-col>
                        <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                            예약자정보
                        </v-col>
                        <v-col cols="12" class="" md="12">
                            <template v-if="!isMobile">
                                <table class="table-left-s table-left-s-b table-font-size-1rem">
                                    <colgroup>
                                        <col style="width:25%"/>
                                        <col style="width:75%"/>
                                    </colgroup>
                                    <tr>
                                        <th class="th">예약자명</th>

                                        <td class="td">
                                            {{resvInfo.resvName}}

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">휴대전화</th>
                                        <td class="td">
                                            {{resvInfo.resvPhone}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">남기실말씀</th>
                                        <td class="td py-4" colspan="3" style=" white-space: pre;">
                                            {{resvInfo.resvMemo}}
                                        </td>
                                    </tr>

                                </table>
                            </template>
                            <template v-else>
                                <table class="table-left-s mobile">
                                    <colgroup>
                                        <col style="width:30%"/>
                                        <col style="width:70%"/>
                                    </colgroup>
                                    <tr>
                                        <th class="th" style="font-size:0.9rem;">예약자명</th>
                                        <td class="td">
                                            {{resvInfo.resvName}}

                                        </td>
                                    </tr>

                                    <tr>
                                        <th class="th" style="font-size:0.9rem;">연락번호</th>
                                        <td class="td">
                                            {{resvInfo.resvPhone}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th" style="font-size:0.9rem;">남기실말씀</th>
                                        <td class="td">
                                            {{resvInfo.resvMemo}}
                                        </td>
                                    </tr>
                                </table>
                            </template>

                        </v-col>
                        <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                            결제정보
                        </v-col>
                        <v-col>
                            <template v-if="!isMobile">
                                <table class="table-left-s table-left-s-b table-font-size-1rem">
                                    <colgroup>
                                        <col style="width:25%"/>
                                        <col style="width:75%"/>
                                    </colgroup>

                                    <tr>
                                        <th class="th">결제금액</th>
                                        <td class="td">
                                            {{resvInfo.resvPrice | comma}}원

                                        </td>
                                    </tr>

                                    <tr>
                                        <th class="th">결제수단</th>
                                        <td class="td">
                                            {{payInfo.payTypeDesc}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">입금계좌</th>
                                        <td class="td">
                                            {{payInfo.bank}} {{payInfo.accountNumber}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th" style="color:red;">입 금 자</th>
                                        <td class="td">
                                            {{resvInfo.payName}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">예약상태</th>
                                        <td class="td">
                                            {{resvInfo.resvGubunDesc}}
                                        </td>
                                    </tr>
                                </table>
                            </template>
                            <template v-else>
                                <table class="table-left-s mobile">
                                    <colgroup>
                                        <col style="width:30%"/>
                                        <col style="width:70%"/>
                                    </colgroup>
                                    <tr>
                                        <th class="th">결제금액</th>
                                        <td class="td red--text" style="font-size: 16px; padding:5px;">
                                            {{resvInfo.resvPrice | comma}}원
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">결제수단</th>
                                        <td class="td">
                                            {{payInfo.payTypeDesc}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">입금계좌</th>
                                        <td class="td"
                                            style="font-size: 1rem; font-weight: bold;padding-top: 5px;padding-bottom: 5px;">
                                            {{payInfo.bank}} {{payInfo.accountNumber}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">입 금 자</th>
                                        <td class="td" style="color:darkblue; font-weight: bold;">
                                            {{resvInfo.payName}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">예약상태</th>
                                        <td class="td">
                                            <template v-if="resvInfo.resvGubun === '1'">
                                                <span style="color:blue;">{{resvInfo.resvGubunDesc}}</span>
                                            </template>
                                            <template v-else-if="resvInfo.resvGubun === '2'">
                                                <span style="color:black;">{{resvInfo.resvGubunDesc}}</span>
                                            </template>
                                            <template v-else>
                                                <span style="color:red;">{{resvInfo.resvGubunDesc}}</span>
                                            </template>
                                            <!--{{resvInfo.resvGubunDesc}}-->
                                        </td>
                                    </tr>
                                </table>
                            </template>

                        </v-col>
                        <template v-if="resvGubun=='3' || resvGubun=='4'">

                            <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                환불 확인
                            </v-col>
                            <v-col>

                                <template v-if="!isMobile">
                                    <table class="table-left-s table-left-s-b table-font-size-1rem">
                                        <colgroup>
                                            <col style="width:25%"/>
                                            <col style="width:75%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">은행</th>
                                            <td class="td">
                                                {{resvInfo.refdBank}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">계좌번호</th>
                                            <td class="td">
                                                {{resvInfo.refdAcc}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">예금주</th>
                                            <td class="td">
                                                {{resvInfo.refdAccName}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">입금한금액</th>
                                            <td class="td">
                                                {{resvInfo.realPrice | comma}}원
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">환불비율</th>
                                            <td class="td">
                                                {{resvInfo.refdPer}}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">환불예상액</th>
                                            <td class="td">
                                                {{resvInfo.refdPrice | comma}}원
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">환불신청일</th>
                                            <td class="td">
                                                {{resvInfo.refdReqDate}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">환불완료일</th>
                                            <td class="td">
                                                {{resvInfo.refdDate}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">요청사항</th>
                                            <td class="td" style=" white-space: pre;">
                                                {{resvInfo.refdMemo}}
                                            </td>
                                        </tr>

                                    </table>
                                </template>
                                <template v-else>
                                    <table class="table-left-s mobile">
                                        <colgroup>
                                            <col style="width:30%"/>
                                            <col style="width:70%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">은행</th>
                                            <td class="td">
                                                {{resvInfo.refdBank}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">계좌번호</th>
                                            <td class="td">
                                                {{resvInfo.refdAcc}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">예금주</th>
                                            <td class="td">
                                                {{resvInfo.refdAccName}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">입금한금액</th>
                                            <td class="td">
                                                {{resvInfo.realPrice | comma}}원
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">환불비율</th>
                                            <td class="td">
                                                {{resvInfo.refdPer}}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">환불예상액</th>
                                            <td class="td">
                                                {{resvInfo.refdPrice | comma}}원
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">환불신청일</th>
                                            <td class="td">
                                                {{resvInfo.refdReqDate}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">환불완료일</th>
                                            <td class="td">
                                                {{resvInfo.refdDate}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">요청사항</th>
                                            <td class="td" style=" white-space: pre;">
                                                {{resvInfo.refdMemo}}
                                            </td>
                                        </tr>
                                    </table>
                                </template>


                            </v-col>
                        </template>
                        <v-col cols="12" class="reservation-select-title mt-6" style="text-align: center">
                            <v-btn
                                    color="error"
                                    class="mx-4 white&#45;&#45;text"
                                    style="font-weight: bold;"
                                    @click="resv_cancel"
                                    v-if="isCancel"
                            >
                                취소신청
                            </v-btn>
                            <v-btn
                                    class="mx-4 center-block"
                                    color="primary"
                                    @click="btnClick(false)"
                            >
                                목록
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-dialog
                v-model="dialog"
                persistent
                width="500"
        >
            <v-col style="background-color: white">
                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                    환불 확인
                </v-col>
            </v-col>
            <v-col style="background-color: white">
                <table class="table-left-s table-left-s-b">
                    <colgroup>
                        <col style="width:30%"/>
                        <col style="width:70%"/>
                    </colgroup>
                    <tr>
                        <th class="th">은행</th>
                        <td class="td">
                            <input v-model="resvInfo.refdBank" placeholder="은행" style="width: 100%;">
                        </td>
                    </tr>
                    <tr>
                        <th class="th">계좌번호</th>
                        <td class="td">
                            <input v-model="resvInfo.refdAcc" placeholder="계좌번호" style="width: 100%;">
                        </td>
                    </tr>
                    <tr>
                        <th class="th">예금주</th>
                        <td class="td">
                            <input v-model="resvInfo.refdAccName" placeholder="예금주" style="width: 100%;">
                        </td>
                    </tr>
                    <tr>
                        <th class="th">입금하신금액</th>
                        <td class="td">
                            <input v-model="resvInfo.realPrice" placeholder="입금하신금액" style="width: 100%;"
                                   @keyup="inputNumberFormat(resvInfo.realPrice)">
                        </td>
                    </tr>
                    <tr>
                        <th class="th">요청사항</th>
                        <td class="td">
                             <textarea v-model="resvInfo.refdMemo"
                                       style="resize: none; overflow:auto;"
                                       wrap="on"
                                       id="textarea"
                                       placeholder="요청사항을 적어 주세요."
                                       @input="input($event.target.value)"></textarea>
                        </td>
                    </tr>
                </table>
                <span style="color:black; font-weight: bold; font-size: 1rem;">
                    ※ 예금주는 입금자명과 같아야 합니다.
                </span>
            </v-col>
            <v-col style="background-color: white">
                <v-col cols="12" style="text-align: center; padding-top:0">
                    <v-btn
                            color="error"
                            class="mx-4 white--text center-block"
                            small
                            @click="refd_check"
                    >
                        취소신청
                    </v-btn>
                    <v-btn
                            color="primary"
                            class="mx-4 white--text center-block"
                            small
                            @click="dialog = false"
                    >
                        취소
                    </v-btn>
                </v-col>
            </v-col>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: 'ReservationSelect',
        components: {},
        data: () => ({
            isCancel: false,
            today: "2000-01-01",

            resvId: "",
            resvPhone: "",
            resvName: "",
            resvGubun: "0",
            roomList: [],
            roomPrice: 0,
            resvInfo: {
                resvPrice: 0,
                resvPrice_edit: 0,
            },
            campItem: [],
            isCampItem: false,
            autoresize: {
                type: [Boolean, String],
                default: false
            },
            isEdit: true,
            payInfo: {
                accountNumber: "",
                bank: "",
                payTypeDesc: ""

            },
            dialog: false,
            isMobile: false,


        }),
        created: function () {

            // 모바일 체크
            this.isMobile = this.$isMobile();

            // 맨위로
            window.scrollTo(0, 0);

            let isCheck = false;

            this.resvId = this.$getParameterByName('resvId');
            this.resvPhone = this.$getParameterByName('resvPhone');
            //this.resvName = this.$getParameterByName('resvName');

            if (this.resvId === "") {
                alert("예약 ID가 없습니다. 다시 시도해주세요.");
                //window.history.back();
                this.$router.push({path: "/reservation/resvConfirm"});
            } else {
                isCheck = true;
            }

            if (this.resvPhone === "") {
                alert("예약 전화번호가 없습니다. 다시 시도해주세요.");
                //window.history.back();
                this.$router.push({path: "/reservation/resvConfirm"});
            } else {
                isCheck = true;
            }

            /*if (this.resvName === "") {
                alert("예약자명이 없습니다. 다시 시도해주세요.");
                //window.history.back();
                this.$router.push({path: "/reservation/resvConfirm"});
            } else {
                isCheck = true;
            }*/


            if (isCheck) {
                this.getInfo();
            }


        },
        methods: {
            getInfo() {

                let fdata = {
                    resvId: this.resvId,
                    resvPhone: this.resvPhone,
                    //resvName: this.resvName,
                };

                return this.$store.dispatch("admin/getResvInfo", fdata)
                    .then((resp) => {
                        setTimeout(() => {

                            if (resp.total === '1') {
                                this.resvInfo = resp.message[0];

                                this.resvGubun = this.resvInfo.resvGubun + "";

                                this.roomList = this.resvInfo.roomData;
                                this.campItem = this.resvInfo.campItem;

                                // 핸드폰 번호 변경
                                this.resvInfo.resvPhone = this.$setPhoneNum(this.resvInfo.resvPhone);

                                if (this.campItem.length > 0) {
                                    this.isCampItem = true;
                                } else {
                                    this.isCampItem = false;
                                }

                                //객실 금액 계산
                                this.roomPrice = 0;
                                for (let i = 0; this.roomList.length > i; i++) {

                                    this.roomPrice += Number(this.roomList[i].resvPrice);

                                }

                                // 계좌이체 무통장 구분
                                if (this.resvInfo.payType === "1") { // 계좌이체

                                    this.payInfo.accountNumber = this.resvInfo.accountNumber;
                                    this.payInfo.bank = this.resvInfo.bank;
                                    this.payInfo.payTypeDesc = "계좌이체";


                                } else { // 무통장

                                   this.payInfo.payTypeDesc = "무통장";
                                    return this.$store.dispatch("admin/getComn", {comnGubun: 'accList'})
                                        .then((resp) => {
                                            setTimeout(() => {

                                                if (resp.message !== undefined && resp.message.comnCont !== undefined) {
                                                    this.accList = JSON.parse(resp.message.comnCont);

                                                    this.accInfo = this.accList[0];

                                                    this.payInfo.accountNumber = this.accInfo.accNum + ' 예금주 ' + this.accInfo.accResvNm;
                                                    this.payInfo.bank = this.accInfo.accBnkNm;


                                                }else{
                                                    this.payInfo.accountNumber = "352-0170-6948-03 예금주 최정자";
                                                    this.payInfo.bank = "농협";
                                                }

                                            }, 300)
                                        })
                                        .catch((err) => {
                                        })

                                }

                                this.$nextTick(() => {
                                    //this.init()
                                    this.resv_check()
                                })
                            } else {
                                alert('잘못된 요청입니다.');
                                this.$router.push({path: "/reservation/resvConfirm"});
                            }
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            resv_check() {
                // 현재 날자 구하기
                let today = new Date();
                let year = today.getFullYear();
                let month = ('0' + (today.getMonth() + 1)).slice(-2);
                let day = ('0' + today.getDate()).slice(-2);
                this.today = year + '-' + month + '-' + day;

                if (this.today <= this.resvInfo.resvDate) {

                    if (this.resvInfo.resvGubun < 3) {
                        this.isCancel = true;
                    }
                }

            },
            resv_cancel() {

                switch (this.resvInfo.resvGubun) {

                    case "1" : {
                        this.resv_cancel_confirm();
                        break;
                    }
                    case "2" : {
                        this.dialog = true;
                        break;
                    }

                }

            },
            refd_check() {

                if (this.resvInfo.refdBank === undefined || this.resvInfo.refdBank.length === 0) {
                    alert("환불 받을 은행을 입력해 주세요.");
                    return;
                }

                if (this.resvInfo.refdAcc === undefined || this.resvInfo.refdAcc.length === 0) {
                    alert("환불 받을 계좌를 입력해 주세요.");
                    return;
                }

                if (this.resvInfo.refdAccName === undefined || this.resvInfo.refdAccName.length === 0) {
                    alert("환불 받을 계좌 예금주를 입력해 주세요.");
                    return;
                }

                /*if (this.resvInfo.realPrice === undefined || this.resvInfo.realPrice.length === 0) {
                    alert("입금하신 금액을 입력해 주세요.");
                    return;
                }*/

                this.resv_cancel_confirm();
            },

            resv_cancel_confirm() {

                if (!confirm("예약을 취소 하시겠습니까?.")) {
                    // 취소(아니오) 버튼 클릭 시 이벤트
                } else {
                    // 확인(예) 버튼 클릭 시 이벤트

                    if (this.today <= this.resvInfo.resvDate) {

                        let fdata = {
                            resvId: this.resvInfo.resvId,
                            resvGubun: this.resvInfo.resvGubun
                        };

                        if (this.resvInfo.resvGubun === "2") {
                            fdata['refdBank'] = this.resvInfo.refdBank;
                            fdata['refdAcc'] = this.resvInfo.refdAcc;
                            fdata['refdAccName'] = this.resvInfo.refdAccName;
                            fdata['realPrice'] = this.uncomma(this.resvInfo.realPrice);
                            fdata['refdMemo'] = this.resvInfo.refdMemo;

                        }


                        return this.$store.dispatch("resvConfirm/cancelResv", fdata)
                            .then((resp) => {
                                setTimeout(() => {

                                    alert(resp.message);

                                    //this.$router.push({path: "/reservation/resvConfirmList"});
                                    location.reload()
                                }, 300)
                            })
                            .catch((err) => {

                            })

                    } else {
                    }
                }
            },
            btnClick() {
                this.$router.push({path: "/reservation/resvConfirmList"});
            },
            comma(str) {
                str = String(str);
                return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
            },
            uncomma(str) {
                str = String(str);
                return str.replace(/[^\d]+/g, '');
            },
            inputNumberFormat(obj) {

                if (this.resvInfo.realPrice != undefined) {
                    this.resvInfo.realPrice = this.resvInfo.realPrice.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                }

            },
            input(value) {
                this.$emit('input', value);
                this.resize()
            },
            resize($event) {
                if (!this.autoresize) return
                this.$textarea.style.height = '1px'
                this.$textarea.style.height = `${this.$textarea.scrollHeight + 12}px`
            },
        },
        mounted() {

            this.$nextTick(() => {

            })
        },
        computed: {
            $textarea() {
                return document.getElementById('textarea')
            },
        },
    }
</script>

<style>

</style>
